import { createSlice } from '@reduxjs/toolkit'

const initialState = {
};

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setPosition: (state, action) => {
            state.location = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setPosition } = locationSlice.actions

export default locationSlice.reducer