import "bootstrap/dist/css/bootstrap.min.css";
import React, { lazy, Suspense, useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import { sessionService } from "redux-react-session";
import "./App.css";
import PrivateRoute from "./private-route";
import { setPosition } from "./redux/LocationReducer";

import Loading from "@g3r-developers/g3-common/dist/loading";

const CheckIn = lazy(() => import("./pages/check-in"));
const Exceptions = lazy(() => import("./pages/exceptions"));
const ExceptionView = lazy(() => import("./pages/exceptions-view"));
const Find = lazy(() => import("./pages/find"));
const GateView = lazy(() => import("./pages/gate"));
const Home = lazy(() => import("./pages/home"));
const Login = lazy(() => import("./pages/login"));
const Moved = lazy(() => import("./pages/moved"));
const PersonalPossessionsPage = lazy(() => import("./pages/personal-possessions"));
const Pulling = lazy(() => import("./pages/pulling"));
const ReleaseView = lazy(() => import("./pages/release"));
const Stock = lazy(() => import("./pages/stock"));

function App({ authenticated, checked, user }) {
    const pos = useSelector(state => state.location.location);
    const dispatch = useDispatch();

    function logout() {
        sessionService.deleteUser();
        sessionService.deleteSession();
    }

    const positionChanged = useCallback(
        position => {
            dispatch(
                setPosition({
                    latitude: position.coords.latitude.toString(),
                    longitude: position.coords.longitude.toString(),
                    accuracy: position.coords.accuracy.toString(),
                })
            );
        },
        [dispatch]
    );

    const positionError = useCallback(err => {
        console.error(err);
    }, []);

    useEffect(() => {
        if (!navigator.geolocation) {
            alert("Please allow location tracking");
            return;
        }

        navigator.geolocation.watchPosition(positionChanged, positionError, {
            enableHighAccuracy: true,
        });
    }, [positionChanged, positionError]);

    return (
        <>
            <BrowserRouter>
                <Suspense fallback={<Loading />}>
                    <div style={{ backgroundColor: "#ccc" }}>
                        <Link to="/">
                            <h1 className="text-center">
                                G3 Yard
                                {authenticated && (
                                    <span className="logout-button btn btn-primary" onClick={logout}>
                                        Logout
                                    </span>
                                )}
                            </h1>
                        </Link>

                        {authenticated && user.resp && (
                            <div className="text-center">
                                <strong>{user.resp.Name} - v20230821</strong>
                            </div>
                        )}

                        {pos && (
                            <div className="text-center">
                                <span>
                                    {pos.latitude},{pos.longitude} - {parseFloat(pos.accuracy).toFixed(2).toString()}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="page-wrapper" style={{ display: "block" }}>
                        <div className={`container-fluid p-0`}>
                            {user && user.resp && <h5 className="text-center">{user.resp.LocationName}</h5>}
                            <Switch>
                                <Route exact path="/login" render={props => <Login />} />
                                <PrivateRoute
                                    exact
                                    path="/"
                                    component={Home}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/stock"
                                    component={Stock}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/moved"
                                    component={Moved}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/find"
                                    component={Find}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/pulling"
                                    component={Pulling}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/check-in"
                                    component={CheckIn}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/exceptions"
                                    component={Exceptions}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/exceptions/:id"
                                    component={ExceptionView}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/gate"
                                    component={GateView}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/gate/:vrm/personal-possessions"
                                    component={PersonalPossessionsPage}
                                    user={user}
                                    authenticated={authenticated}
                                />
                                <PrivateRoute
                                    exact
                                    path="/release"
                                    component={ReleaseView}
                                    user={user}
                                    authenticated={authenticated}
                                />
                            </Switch>
                        </div>
                    </div>
                </Suspense>
            </BrowserRouter>
        </>
    );
}

const mapState = ({ session }) => ({
    checked: session.checked,
    authenticated: session.authenticated,
    user: session.user,
});

export default connect(mapState)(App);
