import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import { sessionService, sessionReducer } from 'redux-react-session';
import locationReducer from "./redux/LocationReducer";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
});

// Add the sessionReducer
const reducer = combineReducers({
    session: sessionReducer,
    location: locationReducer
});

const validateSession = (session) => {
    if (session === null || session.resp === null || session.resp.Expires === null) {
        return false;
    }

    if (session.resp.Expires < new Date()) {
        return false;
    }

    // Kick "AGENCY" out
    if (session.resp.UserId === 14) {
        return false;
    }
    return true;
}

const store = configureStore({
    reducer,
});
const options = { refreshOnCheckAuth: true, redirectPath: '/', validateSession };


// Init the session service
sessionService.initSessionService(store, options).then(() => {

    ReactDOM.render(
        <Provider store={store}>
            <React.StrictMode>
                <Sentry.ErrorBoundary fallback={({ error, componentStack, resetError }) => (
                    <>
                        <h1 className="text-center">An Error has occurred. Please refresh the page</h1>
                        <button className="btn btn-primary text-center" onClick={window.location.reload}>
                            Refresh
                        </button>
                    </>
                )}>
                    <App />
                </Sentry.ErrorBoundary>
            </React.StrictMode>
        </Provider>,
        document.getElementById('root')
    );
});

