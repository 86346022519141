import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function PrivateRoute({ component, exact = false, path, authenticated, checked, user, ...rest }) {
    return (
        <Route
            {...rest}
            exact={exact}
            path={path}
            user={user}
            render={props => {
                if (authenticated) {
                    props.user = user.resp;
                    return React.createElement(component, props);
                }
                else {
                    return <Redirect to="/login" />
                }
            }
            }
        />
    )
}

const mapState = ({ session }) => ({
    checked: session.checked,
    authenticated: session.authenticated,
    user: session.user,
});

export default connect(mapState)(PrivateRoute)